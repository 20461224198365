import React from "react"
import styled, { css } from "styled-components"
import theme from "styled-theming"
import { colors, device } from "../../styles/theme"
import { Container } from "..//containers/Container"
import BreadcrumbLinks from "../BreadcrumbLinks"

const themedPageTitle = theme.variants("mode", "variant", {
  primaryColor: {
    light: css`
      background-color: ${colors.primary.default};
      color: ${colors.offWhite};
    `,
    dark: css`
      background-color: ${colors.primary.default};
      color: ${colors.offWhite};
    `,
  },
  secondaryColor: {
    light: css`
      background-color: ${colors.secondary.default};
      color: ${colors.black};
    `,
    dark: css`
      background-color: ${colors.grayscale.light1};
      color: ${colors.offWhite};
    `,
  },
  accentDark: {
    light: css`
      background-color: ${colors.accentDark.default};
      color: ${colors.black};
    `,
    dark: css`
      background-color: ${colors.accentDark.dark};
      color: ${colors.offWhite};
    `,
  },
  accentLight: {
    light: css`
      background-color: ${colors.accentLight.default};
      color: ${colors.black};
    `,
    dark: css`
      background-color: ${colors.accentLight.dark};
      color: ${colors.black};
    `,
  },
  danger: {
    light: css`
      background-color: ${colors.danger.default};
      color: ${colors.offWhite};
    `,
    dark: css`
      ${colors.danger.default};
      color: ${colors.offWhite};
    `,
  },
  light: {
    light: css`
      background-color: ${colors.offWhite};
      color: ${colors.black};
    `,
    dark: css`
      background-color: ${colors.grayscale.light2};
      color: ${colors.offWhite};
    `,
  },
  grayDark: {
    light: css`
      background-color: ${colors.grayscale.default};
      color: ${colors.offWhite};
    `,
    dark: css`
      background-color: ${colors.grayscale.default};
      color: ${colors.offWhite};
    `,
  },
  black: {
    light: css`
      background-color: ${colors.black};
      color: ${colors.offWhite};
    `,
    dark: css`
      background-color: ${colors.black};
      color: ${colors.offWhite};
    `,
  },
  white: {
    light: css`
      background-color: ${colors.white};
      color: ${colors.black};
    `,
    dark: css`
      background-color: ${colors.grayscale.light2};
      color: ${colors.white};
    `,
  },
  transparent: {
    light: css`
      background-color: transparent;
      color: ${colors.black};
    `,
    dark: css`
      background-color: transparent;
      color: ${colors.white};
    `,
  },
})

const StyledPageTitle = styled.div`
  ${themedPageTitle};
  padding: 2rem 0;
  h1 {
    margin-top: 0;

    @media ${device.sm} {
      margin: 0;
    }
  }
`

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media ${device.md} {
    flex-wrap: nowrap;
  }
`

const PageTitle = ({
  location,
  backgroundColor = "light",
  textAlign,
  title,
  itemLocation = ''
}) => {
  return (
    <StyledPageTitle variant={backgroundColor}>
      <StyledContainer>
        <h1 style={{ textTransform: `capitalize` }}>{title}</h1>
        {location && <BreadcrumbLinks itemLocation={itemLocation} pathname={location.pathname} />}
      </StyledContainer>
    </StyledPageTitle>
  )
}

export default PageTitle
