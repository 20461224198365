import { motion } from "framer-motion"
import { graphql, Link, navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react"
import ReactPaginate from "react-paginate"
import styled from "styled-components"
import { Container } from "../components/containers/Container"
import Layout from "../components/layout"
import PageTitle from "../components/pagebuilder-parts/PageTitle"
import { colors } from "../styles/theme"
import { normalizePath } from "../utils/get-url-path"
const BlogPageStyles = styled(Container)`
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(auto-fill, minmax(40rem, 1fr));
  margin: 10rem auto;

  .gatsby-image-wrapper {
    height: 20vh;
  }
  > article {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: var(--bs);

    .post-meta {
      padding: 2rem;

      div {
        color: ${colors.primary.default};
        margin: 1rem 0;
        display: flex;
        justify-content: space-between;
      }
    }

    a {
      height: 100%;
      text-decoration: none;
    }
    h2 {
      margin: 0;
      font-size: calc(var(--default-font-size) * 1.25);
    }

  }
`
const Index = ({ data, pageContext, location }) => {
  return (
    <Layout>
      <PageTitle title="All About Sheds" location={location} />
      <BlogPageStyles>
        {data.allWpPost.nodes.map((page) => (
          <motion.article whileHover={{y: `-2rem`}} key={page.link}>
            <Link to={normalizePath(page.uri)}>
              <div>
                <div>
                  {!!page?.featuredImage?.node?.localFile?.childImageSharp && (
                    <GatsbyImage image={page.featuredImage.node.localFile.childImageSharp.gatsbyImageData} />
                  )}
                </div>
                <div className="post-meta">
                  <h2>{page.title}</h2>
                  <div>
                    {!!page?.author?.node?.name && (
                      <small>Author: {page?.author?.node?.name}</small>
                    )}
                    {!!page?.date && <small>Posted: {page?.date}</small>}
                  </div>
                  <p dangerouslySetInnerHTML={{ __html: page.excerpt }} />
                </div>
              </div>
            </Link>
          </motion.article>
        ))}
      </BlogPageStyles>
        {pageContext && pageContext.totalPages > 1 && (
          <Container>
            <ReactPaginate
              previousLabel={
                pageContext?.page !== 1 && <button>Previous page</button>
              }
              nextLabel={
                pageContext?.totalPages !== pageContext.page && (
                  <button>Next page</button>
                )
              }
              onPageChange={({ selected }) => {
                const page = selected + 1
                const path = page === 1 ? `/blog/` : `/blog/${page}/`
                navigate(path)
              }}
              disableInitialCallback
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageContext.totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              initialPage={pageContext.page - 1}
            />
          </Container>
        )}
    </Layout>
  );
}
export default Index
export const query = graphql`
  query HomePage($offset: Int!, $perPage: Int!) {
    allWpPost(
      limit: $perPage
      skip: $offset
      filter: { nodeType: { in: ["Post", "Page", "Alot"] } }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        uri
        title
        author {
          node {
            name
          }
        }
        date(formatString: "MMMM DD, YYYY")
        excerpt
        featuredImage {
          node {
            localFile {
              ...Thumbnail
            }
          }
        }
      }
    }
  }
`
