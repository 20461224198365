import React from "react"
import { BiChevronsRight } from "react-icons/bi"
import styled, { css } from "styled-components"
import theme from "styled-theming"
import { colors } from "../styles/theme"
import UniversalLink from "./UniversalLink"

const themedBreadcrumbLink = theme("mode", {
  light: css`
    &,
    a {
      color: ${colors.primary.default};
    }

    &:last-of-type a {
      pointer-events: none;
      color: black;
    }
  `,
  dark: css`
    &,
    a {
      color: ${colors.primary.lighter};
    }

    &:last-of-type a {
      pointer-events: none;
      color: ${colors.grayscale.light5};
    }
  `,
})
const StyledBreadcrumbs = styled.ul`
  margin: 0;
  display: flex;
  list-style-type: none;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex-grow: 1;

  li {
    ${themedBreadcrumbLink};
    margin-left: 1rem;
    display: flex;
    align-items: center;
    font-size: calc(var(--default-font-size) / 1.25);

    &:first-of-type {
      margin-left: 0;
    }
    svg {
      margin-right: 1rem;

      font-size: var(--default-font-size);
    }
  }
  a {
    text-decoration: none;
  }
`

const BreadcrumbLinks = ({ pathname , itemLocation}) => {
  const [, ...splitPathname] = pathname.split("/")
  let breadcrumbSlug = ""
  const breadcrumbArray = splitPathname
    .filter((e) => e)
    .reduce(
      (acc, item) => {

        const inventoryPageLink = item === 'inventory' ? `/locations/${itemLocation}/inventory/` : null
        breadcrumbSlug += `/${item}`
        const finalSlug = inventoryPageLink || breadcrumbSlug
        let linkText = item
          .split("-")
          .join(" ")
          .replace(/\b\w/g, (l) => l.toUpperCase())
        acc.push({ breadcrumbSlug:  finalSlug, linkText })
        return acc
      },
      [{ breadcrumbSlug: "/", linkText: "Home" }]
    )
  return (
    <StyledBreadcrumbs className="fa-ul">
      {breadcrumbArray.map(({ breadcrumbSlug, linkText }, index) => (
        <li key={`breadcrumbSlug-${index}`}>
          {index > 0 && <BiChevronsRight />}
          <UniversalLink to={breadcrumbSlug}>{linkText}</UniversalLink>
        </li>
      ))}
    </StyledBreadcrumbs>
  )
}

export default BreadcrumbLinks
